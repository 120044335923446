.container-fluid {
    padding: 0 !important;
    width: 100vw;
    height: 100vh;
}

//blue #000

.cursor {
    position: absolute;
    color: red;
}

footer {
    height: 6rem;
    background-color: #000;
    color: #fff;
    display: flex !important;
    justify-content: center;
    align-items: center;
    span {
        padding: 0 2rem;
    }
}

.carousel-item img {
    width: 30vw !important;
    cursor: pointer;
    // &:hover {
    //     width: 80vw !important;
    // }
}

article {
    width: 100%;
    height: 100vh;
    min-height: 480px;
    background: center center no-repeat fixed;
    overflow: hidden;
    &.home {
        overflow: hidden;
        video {
            width: 100%;
            transform: rotateY(180deg);
            -webkit-transform: rotateY(180deg); /* Safari and Chrome */
            -moz-transform: rotateY(180deg); /* Firefox */
            -webkit-filter: grayscale(100%) contrast(200%) brightness(100%);
            -moz-filter: grayscale(100%) contrast(200%) brightness(100%);
            -ms-filter: grayscale(100%) contrast(200%) brightness(100%);
            -o-filter: grayscale(100%) contrast(200%) brightness(100%);
            filter: grayscale(100%) contrast(200%) brightness(100%);
        }
    }

    &.work {
        video {
            width: 100vw;
        }

        .post-data {
            background-color: #000;
            color: #fff;
        }
    }

    .post-bg {
        // background-size: contain;
        background-attachment: fixed;
        background-repeat: repeat;
        width: 100%;
        height: auto;
        min-height: 100vh;
        background-position: center center;
    }
}

.home-main {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .title {
        padding: 2rem;
        // min-width: 35rem;
        text-align: center;
        p {
            font-size: 2rem;
            margin: 0;
            color: #fff;
        }
    }
}

.main {
    .post-bg {
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
}

.loop {
    position: fixed;
    top: 0;
    height: 3rem;
    background-color: #000;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    z-index: 999;
    &:hover {
        .marquee span {
            animation-play-state: paused;
        }
    }

    .marquee span {
        display: inline-block;
        padding-right: 2rem;
        animation: marquee 5s linear infinite;
        font-size: 1.3rem;
        a {
            color: #fff;
            text-decoration: none;
        }
    }
}

.post-bg {
    color: #000;
    display: flex;
    align-items: center;
    align-items: flex-start;
    padding: 5rem;
    .post-data {
        max-width: 70vw;
        h2 {
            font-size: 8rem;
        }

        p {
            font-size: 1.5rem;
        }
    }
}

.work {
    border-bottom: 0.3rem solid #000;
}

.works .post-bg {
    background-color: #000;
    ul {
        font-size: 2rem;
        list-style: none;
        li {
            & {
                padding: 1rem;
                a {
                    color: #fff;
                    text-decoration: none;
                }
                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

@media (max-width: 992px) {
    article {
        &.home {
            overflow: hidden;
            video {
                width: auto;
                height: 100%;
            }
        }
    }

    .works .post-bg,
    footer {
        ul {
            font-size: 1rem;
        }
    }

    .carousel-item img {
        width: 40vh !important;
    }
}
