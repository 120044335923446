@font-face {
    font-family: "helveticabold";
    src: url("fonts/helvetica-bold-font-webfont.woff2") format("woff2"),
        url("fonts/helvetica-bold-font-webfont.woff") format("woff");
    /* font-weight: normal;
    font-style: normal; */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    scroll-padding-top: 8rem;
}

::selection {
    color: #000;
}

body {
    font-family: "helveticabold", sans-serif;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #000;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
